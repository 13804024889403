import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SpinnerSvgIcon, TableCell, TableRow } from 'core/ui';
import { Typography } from '@mui/material';
import { Row } from 'core/styled';
import { getTableBodyEmptyErrorMessage } from './getTableBodyEmptyErrorMessage';
import { t } from 'shared/translations';
const TableBodyEmpty = ({ isLoading, isError, error, columnsCount, isEmpty, isSearch, height, width, fitCell }) => {
    const emptySet = (!isLoading || !isError) && isEmpty;
    if (!emptySet)
        return _jsx(_Fragment, {});
    const key = isLoading ? 'loading' : isError ? 'error' : 'emptySet';
    const message = isLoading
        ? t('loading.ellipsis.label')
        : isError
            ? getTableBodyEmptyErrorMessage(error)
            : isSearch
                ? t('nothing.found.matching.your.request')
                : t('there.are.no.items');
    let sx = fitCell ? {} : { padding: '100px', height: height };
    if (width)
        sx = sx ? { ...sx, width } : { width: width };
    return (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: columnsCount, component: "td", align: "center", sx: sx, children: _jsxs(Row, { alignItems: "center", gap: ".75rem", justifyContent: "center", children: [isLoading && _jsx(SpinnerSvgIcon, {}), _jsx(Typography, { variant: "body2", children: message })] }) }) }, key));
};
export { TableBodyEmpty };
